@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

body {
  font-family: "Rubik", serif;
  font-optical-sizing: auto;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html::-webkit-scrollbar, body::-webkit-scrollbar {
  display: none; /* Прячем скроллбар */
}

/* Для Firefox */
html, body {
  scrollbar-width: none; /* Убирает скроллбар в Firefox */
}

button {
    outline: none;
}

button:focus {
    outline: none;
}
